import React from "react";
import "./App.css";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter,Route, Routes} from "react-router-dom"
import CustomerConsent from "../src/Modules/customer-consent/CustomerConsent";
import Home from "../src/Modules/Home/Home";
import Agreement from "./Modules/agreement/Agreement";
import { ViewInspection } from "./Modules/view-inspection/ViewInspection";
import FinalInspectionInventory from "./Modules/Final-inspection/FinalInspectionInventory";



function App() {
  return (
      <BrowserRouter>
        <ToastContainer />
        <Routes>
        <Route path="/approval" element={<CustomerConsent/>} />
        <Route path="/agreement" element={<Agreement/>}/>
        <Route path="/view-inspection" element={<ViewInspection/>}/>
        <Route path="/final-inventory-report" element={<FinalInspectionInventory/>}/>
        <Route path="*" element={<Home/>} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {};

const Navbar = (props: Props) => {

  return (
    <nav className="bg-[#F8F9FA] justify-self-center mb-3">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <span className="text-white font-bold text-xl">
                <Link to="https://greentiger.in/">
                  <img
                    height={"300px"}
                    width={"200px"}
                    src="https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/logo.png"
                  />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

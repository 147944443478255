import React, { useEffect } from 'react'
import Navbar from '../customer-consent/components/Navbar'

type Props = {}

const Home = (props: Props) => {
   useEffect(()=>{
    window.location.href = 'https://greentiger.in';
   })
  return (
    <div className="flex-col justify-self-center">
      <Navbar/>
        </div>
  )
}

export default Home
import React, { useEffect, useState } from 'react'
import { Customer, InventoryData } from './datatype';
import { baseUrl } from '../../API/api';
import customFetch from '../../API/CustomFetch';
import showToastMessage from '../../Toastify/Index';
import Navbar from '../customer-consent/components/Navbar';


export default function FinalInspectionInventory() {
    const seen = new Set<number>();
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [inventory,setInventory] = useState<InventoryData | null>(null)
const [toastShown, setToastShown] = useState(false);
useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const vehicleProfileId = urlParams.get("vp");
  const url = `${baseUrl}/customer-consent/v1/get-customer-profile/${vehicleProfileId}`;
 
  customFetch(url)
    .then((res) => {
      if (res.SUCCESS) {
        setCustomer(res.DATA[0]);
      } else if (res.SUCCESS === false) {
        setCustomer(null);
        showToastMessage(false, "Contact Support on this.");
      }
    })
    .catch((err) => setCustomer(null));
   
}, [toastShown]); 
useEffect(() => {
    if (customer && !toastShown) {
      showToastMessage(true, 'Welcome to Green Family');
      setToastShown(true);
    }
  }, [customer]);
  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
  const vehicleProfileId = urlParams.get("vp");
     const url2= `${baseUrl}/hrc/inventory/v2/get-inventory-details/${vehicleProfileId}`
     customFetch(url2)
     .then((res) => {
       if (res.SUCCESS) {
         setInventory(res.DATA[0]);
       } else if (res.SUCCESS === false) {
         setInventory(null);
         showToastMessage(false, "Contact Support on this.");
       }
     })
     .catch((err) => setInventory(null));
  },[])
  return (
    <div>
    <div className="flex-col justify-self-center" id="pdf-content">
    <Navbar />
    <h1 className="text-2xl font-bold mb-4 text-center"> Customer Information </h1>
    <div className="border border-gray-300 p-4 mb-8">
    <h2 className="text-xl font-bold mb-4">Basic Details</h2>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="text-gray-600">Name:</label>
        <div>{customer?.RiderName}</div>
      </div>
      <div>
        <label className="text-gray-600">Mobile:</label>
        <div>{customer?.MobileNumber}</div>
      </div>
      <div>
        <label className="text-gray-600">Address:</label>
        <div>{customer?.Address}</div>
      </div>
      <div>
        <label className="text-gray-600">Registration No:</label>
        <div>{customer?.Registration_No}</div>
      </div>
      <div>
        <label className="text-gray-600">Chasis No:</label>
        <div>{customer?.Chasis_No}</div>
      </div>
      <div>
        <label className="text-gray-600">GST No:</label>
        <div>{customer?.GST_No}</div>
      </div>
      <div>
        <label className="text-gray-600">PAN No:</label>
        <div>{customer?.PAN_No}</div>
      </div>
      <div>
        <label className="text-gray-600">Kit Selling Price:</label>
        <div>{customer?.kitSellingPrice}</div>
      </div>
      <div>
        <label className="text-gray-600">Conversion Type:</label>
        <div>{customer?.ConversionName}</div>
      </div>
      <div>
        <label className="text-gray-600">Job card Id/Kit Id:</label>
        <div>{inventory?.JobCard}</div>
      </div>
    </div>

   
  </div>
  </div>
  { customer?.documents &&  <div className="border border-gray-300 p-4">
      <h2 className="text-xl font-bold mb-4 justify-self-center"> Documents</h2>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Document Name</th>
              <th className="px-4 py-2">Image Details</th>
            </tr>
          </thead>
          <tbody>
          {customer?.documents?.map((item, index) => {
            if (seen.has(item.QuestionMasterId)) {
                return null; // Skip rendering if this ID has been seen
              }
              seen.add(item.QuestionMasterId); 
            return (
              <tr key={item.QuestionMasterId}>
                <td className="border px-4 py-2">{item.Question}</td>
                <td className="border px-4 py-2">
                  {item.ResponseValue.includes(".jpg") ? (
                    <img className="max-h-screen" src={item.ResponseValue} alt={item.ResponseValue} />
                  ) : (
                    item.ResponseValue
                  )}
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
    
      
    </div>}
    {inventory &&<div className="border border-gray-300 p-4">
      <h2 className="text-xl font-bold mb-4 justify-self-center"> Inventory Details</h2>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Item</th>
              <th className="px-4 py-2">Response</th>
            </tr>
          </thead>
          <tbody>
          {inventory?.components?.map((item, index) => {
            if (seen.has(item.Component_Id)) {
                return null; // Skip rendering if this ID has been seen
              }
              seen.add(item.Component_Id); 
            return (
              <tr key={item.Component_Id}>
                <td className="border px-4 py-2">{item.Product_Name}</td>
                <td className="border px-4 py-2">
                  {item.Serial_No}
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
    
      
    </div>
}
   

  </div>
  )
}




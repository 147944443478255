import React, { useEffect, useState } from "react";
import { DataItem } from "../datatype";
import customFetch from "../../../API/CustomFetch";
import { customer_consent_get_inspection_repair } from "../../../API/api";

const InspectionRepair = () => {
  const [inspect, setInspect] = useState(false);
  const [repair, setRepair] = useState(false);
  const [data, setData] = useState<DataItem[] | null>(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const typeMasterId = urlParams.get("type");
    if (typeMasterId && parseInt(typeMasterId) === 53) {
      setInspect(true);
    } else if (typeMasterId && parseInt(typeMasterId) === 54) {
      setRepair(true);
    }
  }, [inspect, repair]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const typeMasterId = urlParams.get("type");
    const vehicleProfileId = urlParams.get("vp");
    const url = `${customer_consent_get_inspection_repair}/${vehicleProfileId}/${typeMasterId}`;
    customFetch(url).then((res) => {
      if (res.SUCCESS) {
        if (res.DATA.length === 0) {
          window.location.href = "https://greentiger.in";
        }
        setData(res.DATA);
      }
    });
  }, []);
  return (
    <div className="border border-gray-300 p-4">
      <h2 className="text-xl font-bold mb-4">{inspect === true ? "Inspection Information" : repair === true ? "Repair Criteria Information" : ""}</h2>
      {inspect && (
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Question</th>
              <th className="px-4 py-2">Response</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) =>
              item?.Question?.map((questionItem, questionIndex) => (
                <>
                  <tr key={questionItem.QuestionId}>
                    <td className="border px-4 py-2">{questionItem.Question}</td>
                    <td className="border px-4 py-2">{questionItem.Response.includes(".jpg") ? <img className="max-h-screen" src={questionItem.Response} alt={questionItem.Question} /> : questionItem.Response}</td>
                  </tr>
                </>
              ))
            )}
          </tbody>
        </table>
      )}
      {repair && (
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Item</th>
              <th className="px-4 py-2">Cost</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(
              (item, index) =>
                item?.Question?.map((questionItem, questionIndex) => (
                  <>
                    <tr key={questionItem.QuestionId}>
                      <td className="border px-4 py-2">{questionItem.Question}</td>
                      <td className="border px-4 py-2">{item.TypeName === "Soft Queries" ? questionItem.Response.includes(".jpg") ? <img className="max-h-screen" src={questionItem.Response} alt={questionItem.Question} /> : questionItem.Response : item.TypeName === "Repair criteria" ? questionItem.Cost : null}</td>
                    </tr>
                  </>
                ))

              // }
            )}
            {data && (
              <tr>
                <td className="border px-4 py-2 font-bold">Total Cost:</td>
                <td className="border px-4 py-2 font-bold">
                  {data.reduce((total, item) => {
                    if (item.Question && Array.isArray(item.Question)) {
                      return (
                        total +
                        item.Question.reduce((subtotal, question) => {
                          if (question.Cost) {
                            return subtotal + question.Cost;
                          }
                          return subtotal;
                        }, 0)
                      );
                    }
                    return total;
                  }, 0)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InspectionRepair;

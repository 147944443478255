import React, { useEffect, useState } from 'react'
import { Customer } from '../datatype';
import { customer_consent_get_customer_profile } from '../../../API/api';
import customFetch from '../../../API/CustomFetch';
import showToastMessage from '../../../Toastify/Index';

type Props = {}

const CustomerProfile = (props: Props) => {
const [customer, setCustomer] = useState<Customer | null>(null);
const [toastShown, setToastShown] = useState(false);
useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const vehicleProfileId = urlParams.get("vp");
  const url = `${customer_consent_get_customer_profile}/${vehicleProfileId}`;
  customFetch(url)
    .then((res) => {
      if (res.SUCCESS) {
        setCustomer(res.DATA[0]);
      } else if (res.SUCCESS === false) {
        setCustomer(null);
        showToastMessage(false, "Contact Support on this.");
      }
    })
    .catch((err) => setCustomer(null));
}, [toastShown]); 
useEffect(() => {
  if (customer && !toastShown) {
    showToastMessage(true, 'Welcome to Green Family');
    setToastShown(true);
  }
}, [customer]);
    
  return (
    <div className="border border-gray-300 p-4 mb-8">
    <h2 className="text-xl font-bold mb-4">Customer Details</h2>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="text-gray-600">Name:</label>
        <div>{customer?.LeadName}</div>
      </div>
      <div>
        <label className="text-gray-600">Email:</label>
        <div>{customer?.Email}</div>
      </div>
      <div>
        <label className="text-gray-600">Mobile No:</label>
        <div>{customer?.MobileNumber}</div>
      </div>
      <div>
        <label className="text-gray-600">Brand:</label>
        <div>{customer?.Brand_Name}</div>
      </div>
      <div>
        <label className="text-gray-600">Model:</label>
        <div>{customer?.Model_Name}</div>
      </div>
    </div>
  </div>
  )
}

export default CustomerProfile
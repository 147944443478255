import React, { useEffect, useState } from 'react'
import CustomerProfile from '../customer-consent/components/CustomerProfile'
import Navbar from '../customer-consent/components/Navbar'
import { customer_consent_get_inspection_repair } from '../../API/api';
import customFetch from '../../API/CustomFetch';
import { DataItem } from '../customer-consent/datatype';

export const ViewInspection = () => {
  const [data, setData] = useState<DataItem[] | null>(null);
  const [formType, setFormType] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const typeMasterId = urlParams.get("type");
    const vehicleProfileId = urlParams.get("vp");
    const url = `${customer_consent_get_inspection_repair}/${vehicleProfileId}/${typeMasterId}`;
    if (typeMasterId && parseInt(typeMasterId) === 53) {
      setFormType("Inspection Information");
    } else if (typeMasterId && parseInt(typeMasterId) === 54) {
      setFormType("Repair Criteria Information");
    }else if(typeMasterId && parseInt(typeMasterId) === 80){
      setFormType("Final Inspection Report")
    }
    customFetch(url).then((res) => {
      if (res.SUCCESS) {
        console.log(res)
        // if (res.DATA.length === 0 || Object.keys(res.DATA).length === 0) {
        //   window.location.href = "https://greentiger.in";
        // }
        setData(res.DATA);
      }
    });
  }, []);
  return (
    <div className="flex-col justify-self-center" id="pdf-content">
      <Navbar />
      <h1 className="text-2xl font-bold mb-4 text-center">{formType} </h1>
      <CustomerProfile />
      <div className="border border-gray-300 p-4">
      <h2 className="text-xl font-bold mb-4 justify-self-center"> Report Details</h2>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Question/Item</th>
              <th className="px-4 py-2">Response/Cost</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) =>
              item?.Question?.map((questionItem, questionIndex) => (
                <>
                  <tr key={questionItem.QuestionId}>
                    <td className="border px-4 py-2">{questionItem.Question}</td>
                    <td className="border px-4 py-2">{questionItem.Response.includes(".jpg") ? <img className="max-h-screen" src={questionItem.Response} alt={questionItem.Question} /> : questionItem.Response}</td>
                  </tr>
                </>
              ))
            )}
          </tbody>
        </table>
    
      
    </div>
      </div>
  )
}

import React from 'react'
import { agreementData } from '../datatype';
interface UserProfileProps {
    leadData:agreementData; // Allow dynamic content inside the modal
  }
const UserProfile:React.FC<UserProfileProps> = (props) => {
  return (
    <div className="border border-gray-300 p-4 mb-8 md:mx-16">
    <h2 className="text-xl font-bold mb-4">Customer Details</h2>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="text-gray-600">Name:</label>
        <div>{props.leadData?.LeadName}</div>
      </div>
      <div>
        <label className="text-gray-600">Email:</label>
        <div>{props.leadData?.Email}</div>
      </div>
      <div>
        <label className="text-gray-600">Mobile No:</label>
        <div>{props.leadData?.MobileNumber}</div>
      </div>
      <div>
        <label className="text-gray-600">Brand:</label>
        <div>{props.leadData?.Brand_Name}</div>
      </div>
      <div>
        <label className="text-gray-600">Model:</label>
        <div>{props.leadData?.Model_Name}</div>
      </div>
      <div>
      <label className="text-gray-600">Registration No:</label>
        <div>{props.leadData?.RegistrationNo}</div>
      </div>
    </div>
  </div>
  )
}

export default UserProfile
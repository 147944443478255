import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
const showToastMessage = (messageType: boolean, message: string,toastID?:string) => {
  messageType
    ? toast.success("Success!", {
        autoClose: 500,
        position: toast.POSITION.TOP_RIGHT,
        toastId:toastID
      })
    : toast.error(message, {
        autoClose: 500,
        position: toast.POSITION.TOP_RIGHT,
      });
};
export default showToastMessage;

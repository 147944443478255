import showToastMessage from "../Toastify/Index";
type methodType = {
  method: string;
};

const customFetch = async (
  url: string,
  body?: BodyInit,
  method?: methodType
) => {
  const config: RequestInit = {
    ...method,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  };
  if (body) {
    config.body = body;
  }
  try {
    let response = await fetch(url, config);
    let data = await response.json();
    if (data) {
      return data;
    } else {
      throw new Error("data not fetched");
    }
  } catch (error) {
    showToastMessage(false, "server error");
  }
};
export default customFetch;